.timeline {
    margin-top: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  
  .play_stop_buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 8px;
  }

  .play_stop_buttons > button {
    background: none;
    border: none;
    width: 80px;
  }

  .play_stop_icon {
    width: 45px;
  }

  .chord_box {
    width: 125px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    align-items: center;
    margin: 1px;
  }
  
  .chord_label {
    font-size: 40px;
    position: absolute;
    text-align: center;
    cursor: cell;
    padding-top: 60px;
    height: 100%;
    width: 100%;
  }
  
  .delete_chord {
    width: 80%;
    height: 30px;
    z-index: 1;
    background: none;
    border: none;
    margin-top: 3px;
  }

  .delete_chord img {
    width: 20px;
  }

  .delete_chord:active {
    filter: invert(100%)
  }
  
  .move_chord_buttons > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: none;
    border: 1px solid black;
    margin: 2px;
  }

  .move_chord_buttons img {
    width: 20px;
  }

  .move_chord_buttons > button:hover {
    filter: hue-rotate(150deg);
  }

  .move_chord_buttons > button:active {
    filter: invert(100%);
  }

  .scrubber_buttons img, .jump_buttons img {
    width: 30px;
  }

  .scrubber_buttons {
    width: 500px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .scrubber_buttons > button, .jump_buttons > button {
    background: none;
    border: none;
  }

  .scrubber_buttons > button:active, .jump_buttons > button:active {
    filter: opacity(50%);
  }
  
  .slide_container {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }
  
.jump_buttons {
  width: 500px;
  display: flex;
  justify-content: space-between;
}

.plus {
  color: white;
}

.highlighted {
  filter: invert(95%);
  color: white;
  background: black;
  top: 0px !important;
  padding-top: 45px;
  height: 100%;
  width: 100%;
}

.highlighted_plus {
  filter: invert(95%);
  background-color: black;
  color: white;
  top: 0px !important;
  height: 100%;
  width: 100%;
}

.speed_slider {
  direction: rtl
}

.info_label {
  font-size: 15px;
}

.prog_name {
  margin-bottom: 15px;
  margin-top: 15px;
}

.playback_error {
  color: red;
  text-align: center;
  margin: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.note_container {
  margin-bottom: 10px;
}

/* scale colorization */
.maj { 
  filter: grayscale(0%);
}

.min {
  filter: grayscale(10%);

}

.maj7 {
  filter: grayscale(20%);

}

.min7 {
  filter: grayscale(30%);

}

.dim {
  filter: grayscale(40%);

}

.dom7 {
  filter: grayscale(50%);

}

.sus2 {
  filter: grayscale(60%);
}

.sus4 {
  filter: grayscale(70%);
}

.aug {
  filter: grayscale(80%);
}

.global {
  color: black;
  font-family: Arial, sans-serif !important;
}

.global_inactive {
  filter: grayscale(80%);
  color: white;
  font-family: Arial, sans-serif !important;
}

/* chord colorization */

.a {
  background-color: #D41111;
}

.bb {
  background-color: #FFCF5D;
}

.b {
  background-color: #FF8A00;
}

.c {
  background-color: #FFF600;
}

.db {
  background-color: #96D782;
}

.d {
  background-color: #46B523;
}

.eb {
  background-color: #82D2D7;
}

.e {
  background-color: #0CB4D2;
}

.f {
  background-color: #7b62c4;
}

.gb {
  background-color: #DA81E7;
}

.g {
  background-color: #B80CD2;
}

.ab {
  background-color: #FF8282;
}

@media screen and (max-width: 700px) {
  .volume {
    margin-bottom: 16px;
  }

  .jump_buttons {
    width: 300px;
    margin-top: 10px;
  }
}

@media screen and (min-width: 700px) {
  .chord_info {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .info_label::after {
    content: ' |';
  }
  .info_label::before {
    content: '| ';
  }
}
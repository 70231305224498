.hidden {
    display: none;
  }
  
  .login {
    margin-top: 150px;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .login input {
    border: 1px solid black;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 3px;
    font-size: 15px;
  }
  
  .login_form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  
  }
  
  .login_form legend {
    margin-bottom: 20px;
  }

  .username, .password {
    margin-top: 5px;
  }
  
  fieldset {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  
  .login_form button {
    text-align: center;
    border: 1px solid black;
    width: 55px;
    margin-left: auto;
    margin-right: auto;
  }

  .error {
    color: red;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
  }

.intro_header {
    background-color: #27CCBE;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
  }

  .intro_header h1 {
    height: 45px;
      font-size: 36px;
      animation: animation-hero 1s linear infinite;
      background-size: 800% 800%;
      background-image: repeating-linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
  }

  .intro_header h2 {
      font-size: 18px;
      line-height: 30px;
  }

  .splash_login a, .splash_register a, .splash_continue a {
    font-style: none;
    color: darkturquoise;
  }
  
  .splash_login:hover, .splash_register:hover, .splash_continue:hover, .readme_link:hover {
    color: white;
  }
  
  .splash_login:active, .splash_register:active, .splash_continue:active, .readme_link:active {
    color: gray;
  }

  .getting_started {
    background-color: #27CCBE;
    text-align: center;
    padding-top: 35px;
    padding: 35px;
    line-height: 26px;
  }

  .bold {
    font-size: 25px;
  }

  .instructions {
    margin-left: auto;
    margin-right: auto;
    max-width: 700px;
    font-size: 18px;
  }
  
  .splash_login_links {
    background-color: #27CCBE;
    height: 150px;
    text-align: center;
    padding-top: 30px;
    line-height: 40px;
  }

  .readme_link {
    color: indigo;
  }

  .splash_login_links a {
    color: indigo;
  }

  .timeline_photo {
    width: 200px;
  }

  @media screen and (max-width: 700px) {
    .intro_header h1 {
        font-size: 28px;
        height: 75px;
    }
    .intro_header h2 {
        font-size: 15px;
    }
    .intro_header {
        height: 150px;
        text-align: center;
    }
  }
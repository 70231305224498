html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}

* {
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding-bottom: 50px;
}

nav {
  width: 100%;
  height: 45px;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  background-color: #27CCBE;
}

.timeline {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.global_view {
  margin-top: 5px;
  margin-bottom: 5px;
}

.play_stop_buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.player_timeline {
  width: 500px;
  height: 180px;
  background-color: black;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
}

.chord_label {
  font-size: 40px;
  position: absolute;
  text-align: center;
}

.move_chord_buttons {
  display: flex;
  height: 35px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.move_chord_buttons > button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scrubber_buttons {
  width: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.slide_container {
  text-align: center;
}

.library_container {
  margin-top: 30px;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.library_settings {
  display: flex;
  flex-direction: row-reverse;
  width: 700px;
  height: 300px;
  background: #27CCBE;
  border-radius: 15px;
}

.wave_icon {
  width: 50px;
}

[type=radio] { 
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* IMAGE STYLES */
[type=radio] + img {
  cursor: default;
}

.wave_icon:hover {
  outline: 1px solid lightgray;
}

/* CHECKED STYLES */
[type=radio]:checked + img {
  outline: 1px solid #000000;
}

.wave_icons_container {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
}

.instrument_container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.library {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.scales {
  list-style: none;
  padding-left: 0;
  columns: 3;
}

.chords {
  list-style: none;
  padding-left: 0;
  columns: 3;
}

.scale a, .scale a:visited {
  text-decoration: none;
  color: black;
}

.scale, .note {
  font-size: 50px;
  width: 150px;
  text-align: center;
  margin-bottom: 10px;
  cursor: copy;
}

.note {
  padding-bottom: 5px;
}

.scale {
  background: linear-gradient(to right, #90C1DE 0 8.33%, #F7591E 8.33% 16.66%, #2AB1A2 16.66% 25%, #FDF147 25% 33.32%, #A0E2CF 33.32% 41.65%, #ED87DD 41.65% 50%, #E26112 50% 58.31%, #72C7C0 58.31% 66.64%, #6FDFAB 66.64% 74.97%, #8793FF 74.97% 83.3%, #E0B076 83.3% 91.63%, #89C781 91.63% 100%);
  height: 70px;
  padding-top: 5px;
}

.settings {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 25%;
  background-color: #3E807A;
  border-top-right-radius: 15px;
  border-bottom: none;
  padding-top: 10px;
  padding-bottom: 10px;
}

.oct_ins_pre {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.prehear_container, .instrument_container, .octave_container {
  margin-bottom: 15px;
}

.octave_container {
  text-align: center;
  line-height: 25px;
}

.octave {
  width: 100px;
}

.login_message {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.hidden {
  display: none;
}

.save_progression {
  width: 90px;
  border: 1px solid black;
  background: none;
  font-size: 15px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.new_save_tooltip, .update_tooltip {
  position: absolute;
  background: white;
  opacity: .8;
  border: 1px solid black;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  z-index: 999;
}

.new_save_tooltip input, .update_tooltip input {
  border: 1px solid black;
  font-size: 15px;
  padding-left: 3px;
}

.new_save_tooltip button, .update_tooltip button {
  border: 1px solid black;
  background: none;
  padding: 3px;
}

.back {
    background: none;
    text-decoration: none;
    border: none;
    font-size: 20px;
    margin: 5px;
}

.selected {
    color: white;
    background-color: black;
}

.save_cancel {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.hidden {
  display: none;
}

.scale:hover, .note:hover {
  opacity: 0.75;
}

.insert_chord {
  width: 70px;
  padding: 5px;
  background: none;
  font-size: 18px;
  border: none;
}

.insert_chord:disabled {
  border: none;
}

.insert_chord:hover:enabled {
  animation: animation-insert 1s linear infinite;
  background-size: 800% 800%;
  background-image: repeating-linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
}

.insert_chord:active:enabled {
  color: black;
}

/* scale colorization */
.maj { 
  filter: grayscale(0%);
}

.min {
  filter: grayscale(10%);

}

.maj7 {
  filter: grayscale(20%);

}

.min7 {
  filter: grayscale(30%);

}

.dim {
  filter: grayscale(40%);

}

.dom7 {
  filter: grayscale(50%);

}

.sus2 {
  filter: grayscale(60%);
}

.sus4 {
  filter: grayscale(70%);
}

.aug {
  filter: grayscale(80%);
}

/* chord colorization */

.a {
  background-color: #D41111;
}

.bb {
  background-color: #FFCF5D;
}

.b {
  background-color: #FF8A00;
}

.c {
  background-color: #FFF600;
}

.db {
  background-color: #96D782;
}

.d {
  background-color: #46B523;
}

.eb {
  background-color: #82D2D7;
}

.e {
  background-color: #0CB4D2;
}

.f {
  background-color: #7b62c4;
}

.gb {
  background-color: #DA81E7;
}

.g {
  background-color: #B80CD2;
}

.ab {
  background-color: #FF8282;
}

@keyframes animation-insert {
  0% {
    background-position: right;
  }
  100% {
    background-position: left;
  }
}

@media screen and (max-width: 700px) {

  .player_timeline {
    width: 300px;
  }

  .scrubber_buttons {
    width: 300px;
    justify-content: space-around;
    padding-top: 10px;
  }

  .slide_container {
    margin-left: 10px;
    margin-right: 10px;
  }

  .volume_slider, .speed_slider {
    width: 100px;
  }

  .library_settings {
    flex-direction: column;
    width: 325px;
    height: 800px;
  }

  .settings {
    height: 25%;
    width: 100%;
    flex-direction: row;
    border-top-left-radius: 15px;
  }

  .library {
    width: 100%;
  }

  .oct_ins_pre {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    margin-left: auto;
    margin-right: auto;
    height: 100%
  }

  .new_save_tooltip, .update_tooltip {
    bottom: initial;
  }

  .scale, .note {
    width: 130px;
    margin-bottom: 40px;
  }

  .note {
    display: inline-block;
    font-size: 40px;
  }

  .scale {
    display: inline-block;
    font-size: 50px;
  }

  .scales {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-count: 100px;
    -moz-column-width: 100px;
    column-width: 100px;
    padding-top: 0;
    margin: auto;
    width: 87%;
    margin-top: 30px;
  }

  .chords {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    -webkit-column-count: 100px;
    -moz-column-width: 100px;
    column-width: 100px;
    padding-top: 0;
    margin: auto;
    width: 87%;
  }

  .login_message {
    width: 130px;
  }

  .back {
    margin: 15px;
  }
 
  .login_message {
    width: 100px;
  }

  .insert_chord {
    margin-left: auto;
    margin-right: auto;
  }

  .insert_chord:disabled {
    color: lightgray;
    border: none;
  }

  .insert_chord:enabled {
    color: black;
  }

  .save_progression {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (min-width: 700px) {
  .settings {
    border-bottom-right-radius: 15px;
  }
}
.saved_container {
    margin-top: 100px !important;
}

.progressions {
    width: 100%;
  }
  
.progression {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid black;
}

.progression button {
    width: 100%;
    height: 100%;
    font-size: 20px;
    background: none;
}

.saved_settings {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 25%;
    background-color: lightcoral;
    border-top-right-radius: 15px;
    border-bottom: none;
    padding-top: 10px;
    padding-bottom: 10px;
    
}

.saved_settings button {
    background: none;
    width: 90px;
    font-size: 12px;
    border: 1px solid black;
    padding: 5px;
}

  
.saved_library {
    width: 75%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: auto;
}

.highlighted_prog {
    color: white;
    background: black !important;
    top: 0px !important;
    height: 100%;
    width: 100%;
}

.library_container {
    margin-top: 50px;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.saved_settings button, .back_progression {
    width: 50px;
    height: 30px;
}

.forbidden {
    color: red;
    font-size: 15px;
    text-align: center;
    margin-top: 100px;
}

@media screen and (max-width: 700px) {
    .saved_settings {
        height: 15%;
        width: 100%;
        flex-direction: row;
        border-top-left-radius: 15px;
    }

      .saved_library {
          width: 100%;
      }

      .no_progs {
          text-align: center;
          margin-top: 50px;
      }
}

@media screen and (min-width: 700px) {
    .saved_settings {
        border-bottom-right-radius: 15px;
    }

    .saved_library {
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }

    .no_progs {
        text-align: center;
        margin-top: 125px;
    }
}
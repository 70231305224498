.response {
    position: absolute;
    text-align: center;
}

.success {
    color: green;
}

.failure {
    color: red;
}